<template>
  <div v-if="wizard.askFirewall" class="riepilogo-wrapper">
    <ul>
      <wizard-summary-option
        :name="_t('Firewall')"
        :value="wizard.addOnFirewall ? _t('Dedicato') : _t('A bordo del server')"
        editStep="WizardStepProdottiInfrastruttura"
        ouia-id="wizard-summary-firewall-attivazione"
      />
      <template v-if="wizard.addOnFirewall && wizard.path === 'cloud'">
        <wizard-summary-option :name="_t('Risorse')" ouia-id="wizard-summary-firewall-risorse">
          {{ wizard.cpuFirewallQt }} <cpu-icon title="cpu" /> {{ wizard.ramFirewallQt }} GB <memory-icon title="ram" /> {{ wizard.storageFirewallQt }} GB <volume-icon title="storage" />
        </wizard-summary-option>
        <wizard-summary-option :name="_t('Sistema operativo')" value="OPNSense" ouia-id="wizard-summary-firewall-os" />
      </template>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import CpuIcon from '@vue-patternfly/icons/cpu-icon';
import MemoryIcon from '@vue-patternfly/icons/memory-icon';
import VolumeIcon from '@vue-patternfly/icons/volume-icon';
import { useWizard } from '../composition';
import { $t as _t } from '@/i18n';

const wizard = useWizard();
</script>
