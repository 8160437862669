<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="title">
    <div v-if="wizard.askOs || wizard.askDurataFatturazione" class="row">
      <div v-if="wizard.askOs" class="col-md-3">
        <h3>{{ $t('Sistema Operativo') }}</h3>
        <wizard-question-os
          v-model="wizard.os"
          required
        />
      </div>
      <div v-if="wizard.askDurataFatturazione" class="col-md-3">
        <h3>{{ $t('Ricorrenza ') }}:</h3>
        <toggle
          v-model="wizard.fatturazioneAnnuale"
          :on-value="true"
          :off-value="false"
          :on-text="$t('Annuale')"
          :off-text="$t('Mensile')"
          off-class="btn-success"
        />
      </div>
    </div>

    <div v-if="wizard.path === 'cloud'" class="question-wrapper">
      <wizard-configuratore-cloud
        v-model:cpu-qtn="wizard.cpuCloudQt"
        v-model:ram-qtn="wizard.ramCloudQt"
        v-model:storage-qtn="wizard.storageCloudQt"
      />
    </div>

    <div v-else class="question-wrapper">
      <wizard-question-prodotti
        v-model="wizard.sceltaProdottoInfrastruttura"
        v-model:qt="wizard.storageQt"
        :ask-qt="wizard.askStorageQt"
        :prodotti="wizard.opzioniProdottiInfrastruttura()"
        name="sceltaProdottoInfrastruttura"
        show-feature
        :variant="wizard.path === 'storage' ? 'list' : 'table'"
        required
      />
    </div>

    <div v-if="wizard.askNomeServer" class="question-wrapper">
      <h3>{{ $t('Nome del server') }}</h3>
      <wizard-question-nomeserver
        v-model="wizard.nomeServer"
      />
    </div>

    <div v-if="wizard.askFirewall" class="question-wrapper">
      <h3>{{ $t('Vuoi attivare anche un firewall dedicato?') }}</h3>
      <wizard-question-prodotto
        v-model="wizard.addOnFirewall"
        :product="wizard.firewall"
        show-prezzo
        :off-text="$t('No, utilizzo quello a bordo del server')"
      />
    </div>
  </wizard-form>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import Toggle from '../../toggle.vue';
import WizardQuestionOs from '../questions/wizard-question-os.vue';
import wizardQuestionProdotto from '../questions/wizard-question-prodotto.vue';
import WizardQuestionProdotti from '../questions/wizard-question-prodotti.vue';
import WizardConfiguratoreCloud from '../questions/wizard-configuratore-cloud.vue';
import WizardQuestionNomeserver from '../questions/wizard-question-nomeserver.vue';
import WizardForm from '../wizard-form.vue';

defineOptions({
  name: 'WizardStepProdottiInfrastruttura',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => wizard.askProdotti,
});

defineExpose({
  isEnabled,
});

const title = computed(() => wizard.path === 'cloud' ? "Configura il tuo server" : "Scelta del prodotto");
</script>
