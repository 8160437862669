 <template>
  <h4>{{ $t('Dati del registrante del dominio {0}', [domain.dominio]) }}</h4>
  <form role="form" method="post" @submit.prevent="send">
    <input type="hidden" name="id" :value="domainData.id">

    <div class="row">
      <form-group
        :name="_t('Codice authcode')"
        :help-tooltip="_t('Si tratta del codice che occorre per poter trasferire il dominio, se non lo hai devi richiederlo al tuo attuale provider che è obbligato a inviartelo.')"
        class="col-lg-3 col-sm-6">
        <div>
          <x-input
            id="domainAuthcode"
            v-model="domainData.authcode"
            type="text"
            name="authcode"
          />
        </div>
      </form-group>
    </div>

    <div v-if="login.complete" class="billingRadio">
      <div>
        <label>
          <input type="radio" v-model="domainData.usa_dati_fatturazione" name="usa_dati_fatturazione" :value="true" required>
          {{ _t(`Intesta il dominio a ${login.azienda}`) }}
        </label>
      </div>
      <div>
        <label>
          <input type="radio" v-model="domainData.usa_dati_fatturazione" name="usa_dati_fatturazione" :value="false" required>
          {{ _t('Oppure inserisci i dati del registrante') }}
        </label>
      </div>
    </div>

    <fieldset v-if="!domainData.usa_dati_fatturazione">
      <form-group :name="_t('Tipologia')" required>
        <div>
          <div class="btn-group">
            <btn-radio v-model="domainData.tipologia" checked-value="Private" name="tipologia">
              {{ _t('Persona fisica') }}
            </btn-radio>
            <btn-radio v-model="domainData.tipologia" checked-value="Company" name="tipologia">
              {{ _t('Azienda') }}
            </btn-radio>
          </div>
        </div>
      </form-group>

      <div class="row">
        <form-group :name="_t('Nome')" required class="col-lg-3 col-sm-6">
          <x-input
            id="domainName"
            v-model="domainData.nome"
            type="text"
            name="nome"
            required
          />
        </form-group>

        <form-group :name="_t('Cognome')" required class="col-lg-3 col-sm-6">
          <x-input
            id="domainSurname"
            v-model="domainData.cognome"
            type="text"
            name="cognome"
            required
          />
        </form-group>
      </div>

      <div v-if="!personaFisica" class="row">
        <form-group :name="_t('Ragione Sociale')" required class="col-md-12 col-lg-6">
          <x-input
            id="domainDataRagioneSociale"
            v-model="domainData.ragione_sociale"
            name="ragione_sociale"
            type="text"
            required
            maxlength="250"
          />
        </form-group>
      </div>

      <div class="row">
        <form-group :name="_t('Nazione')" required class="col-md-8 col-lg-4">
          <select
            id="domainDataNazione"
            v-model="domainData.stato"
            name="stato"
            class="form-control"
            autocomplete="country"
          >
            <option v-for="country in countries" :key="country.iso" :value="country.iso">
              {{ country.country }}
            </option>
          </select>
        </form-group>

        <form-group :name="_t('CAP')" required class="col-md-4 col-lg-2">
          <x-input
            id="domainDataCAP"
            v-model="domainData.cap"
            name="cap"
            type="text"
            required
            maxlength="10"
            autocomplete="postal-code"
          />
        </form-group>
      </div>

      <div class="row">
        <form-group :name="personaFisica ? _t('Indirizzo residenza') : _t('Indirizzo sede')" required class="col-md-5 col-lg-4">
          <x-input
            id="domainDataIndirizzo"
            v-model="domainData.indirizzo"
            name="indirizzo"
            type="text"
            required
            maxlength="250"
            autocomplete="street-address"
          />
        </form-group>

        <form-group :name="_t('Città')" required class="col-md-5 col-lg-2">
          <x-input
            id="domainDataCitta"
            v-model="domainData.citta"
            name="citta"
            type="text"
            required
            maxlength="250"
          />
        </form-group>

        <form-group :name="domainData.stato == 'CH' ? _t('Cantone') : _t('Provincia')" required class="col-md-2">
          <x-input
            id="domainDataProvincia"
            v-model="domainData.provincia"
            name="provincia"
            type="text"
            required
            maxlength="2"
          />
        </form-group>
      </div>

      <div class="row">
        <form-group v-if="!personaFisica" :name="_t('Partita IVA')" :required="piCfRequired" class="col-md-5 col-lg-4">
          <x-input
            id="domainDataPiva"
            v-model="domainData.partita_iva"
            name="partita_iva"
            type="text"
            :required="piCfRequired"
            maxlength="16"
          />
        </form-group>

        <form-group v-else-if="domainData.stato == 'IT'" :name="_t('Codice fiscale')" :required="piCfRequired" class="col-md-5 col-lg-4">
          <x-input
            id="domainDataCF"
            v-model="domainData.codice_fiscale"
            name="codice_fiscale"
            type="text"
            :required="piCfRequired"
            maxlength="16"
          />
        </form-group>
      </div>
    </fieldset>

    <pf-button type="submit" variant="primary" :disabled="sending">
      {{ _t('Invia') }}
    </pf-button>{{ ' ' }}<em>* {{ _t('Campi obbligatori') }}</em>
  </form>
</template>

<script lang="ts" setup>
import { type Ref, ref, computed } from 'vue';
import { $t as _t } from '@/i18n';
import { type DomainRegistrant, DomainRegistrantResource } from '@/resources';
import { useLoginStore } from '@/store/login';
import XInput from './x-input.vue';
import FormGroup from './form-group.vue';
import BtnRadio from '@/components/btn-radio.vue';
import countries from '../countries.json';

const props = defineProps<{
  idAzienda: number;
  domain: {
    id: number,
    dominio: string,
    codice: string,
  };
}>();

const emit = defineEmits<{
  (e: 'submit'): void;
}>();

const login = useLoginStore();
const sending = ref(false);

const domainData: Ref<DomainRegistrant> = ref({
  id: props.domain.id,
  usa_dati_fatturazione: login.complete,
  authcode: '',
  tipologia: 'Company',
  nome: '',
  cognome: '',
  ragione_sociale: '',
  stato: '',
  cap: '',
  indirizzo: '',
  citta: '',
  provincia: '',
  partita_iva: '',
  codice_fiscale: '',
});

const personaFisica = computed(() => domainData.value.tipologia == 'Private');
const piCfRequired = computed(() => props.domain.codice === 'REGIT' || domainData.value.stato == 'IT');

async function send(e: Event) {
  if (!(e.target instanceof HTMLFormElement)) {
    return;
  }

  sending.value = true;

  try {
    await new DomainRegistrantResource().post(domainData.value);

    emit('submit');
  } finally {
    sending.value = false;
  }
}
</script>
