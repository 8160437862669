<template>
  <pf-list-item>
    <template #heading>
      {{ dominio }}
    </template>

    <template #additional-info>
      <pf-list-item-additional-info v-if="pannello" expandable ouia-id="list-item-web-mail">
        <circle-nodes-icon /> {{ titlePannello }}

        <template #expansion>
          <service-detail-pannello
            :dominio="dominio"
            :pannello="pannello"
            :currency="login.currency"
            :index="index"
            :with-developers-grants="login.grants.developer"
          />
        </template>
      </pf-list-item-additional-info>

      <pf-list-item-additional-info v-if="posta" expandable ouia-id="list-item-web-mail">
        <envelope-icon /> {{ _t('Email') }}

        <template #expansion>
          <service-detail-pannello
            :dominio="dominio"
            :pannello="posta"
            :currency="login.currency"
            :index="index"
            is-posta
            :with-developers-grants="login.grants.developer"
          />
        </template>
      </pf-list-item-additional-info>

      <pf-list-item-additional-info v-if="pecs" expandable ouia-id="list-item-pec">
        <envelope-icon /> {{ _t('PEC') }}

        <template #expansion>
          <h3>{{ _t('Caselle di posta elettronica certificata') }}</h3>
          <div class="list-pf">
            <div v-for="pec in pecs" :key="pec" class="list-pf-item">
              <div class="list-pf-container">
                <div class="list-pf-content list-pf-content-flex">
                  <div class="list-view-pf-left">
                    <span class="fa list-view-pf-icon-md">
                      <envelope-icon />
                    </span>
                  </div>
                  <div class="list-pf-body">
                    <a :href="`mailto:${pec}@${dominio}`" target="_blank" rel="noreferer">{{ pec }}@{{ dominio }}</a>
                  </div>
                </div>

                <div class="list-pf-actions">
                  <a href="https://mail.arterapec.it" class="btn btn btn-default" target="_blank" rel="noreferer">
                    {{ _t('Webmail') }} <arrow-up-right-from-square-icon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </pf-list-item-additional-info>

      <pf-list-item-additional-info v-if="redirect !== undefined" expandable ouia-id="list-item-redirect">
        <arrow-icon /> {{ _t('Redirect') }}

        <template #expansion>
          <div class="row">
            <div class="col-md-6">
              <form-group :name="_t('Destinazione')">
                <form-input
                  :id="`service-redirect-${index}`"
                  :disabled="!login.grants.developer"
                  name="redirect"
                  :model-value="redirect"
                  @save="save"
                  @update:model-value="$emit('update:redirect', $event)"
                />
              </form-group>
            </div>
          </div>
        </template>
      </pf-list-item-additional-info>

      <pf-list-item-additional-info v-if="dns" expandable ouia-id="list-item-domain-dns">
        <domain-icon /> {{ _t('Gestione dominio e DNS') }}

        <template #expansion>
          <template v-if="dns">
            <h3>{{ _t('Zona DNS') }}</h3>
            <dns-records :dominio="dominio" />
          </template>
          <template v-if="isRegistrazioneDominio">
            <h3>{{ _t('Trasferisci altrove il dominio') }}</h3>
            <pf-button @click="richiediAuthcode(dominio)">{{ _t('Richiedi AuthCode') }}</pf-button>
          </template>
        </template>
      </pf-list-item-additional-info>
    </template>
  </pf-list-item>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useAppStore } from '@/store/app';
import { $t as _t } from '@/i18n';
import { type ServicePannello } from '@/resources';
import FormInput from '../components/form-input.vue';
import DnsRecords from '../components/dns-records.vue';
import ServiceDetailPannello from './service-detail-pannello.vue';
import { useLoginStore } from '@/store/login';
import { Resource, TicketResource } from '@/resources';
import ArrowUpRightFromSquareIcon from '@vue-patternfly/icons/arrow-up-right-from-square-icon';
import CircleNodesIcon from '@vue-patternfly/icons/circle-nodes-icon';
import DomainIcon from '@vue-patternfly/icons/domain-icon';
import ArrowIcon from '@vue-patternfly/icons/arrow-icon';
import EnvelopeIcon from '@vue-patternfly/icons/envelope-icon';
import FormGroup from './form-group.vue';

const props = defineProps<{
  index: number,
  dominio: string,
  pannello?: ServicePannello;
  posta?: ServicePannello;
  pecs?: string[];
  redirect?: string;
  dns?: boolean;
  isRegistrazioneDominio?: boolean;
}>();

defineEmits<{
  (e: 'update:redirect', event: string): void
}>();

const login = useLoginStore();

const titlePannello = computed(() => props.pannello && props.posta ? 'Web' : _t('Web / Email'));

async function save(value: string, name: string | undefined) {
  if (!name) {
    return;
  }

  try {
    await new Resource('services').save(props.dominio, {
      [name]: value,
    });
  } catch (ex: any) {
    if (!ex.response || ![401, 404, 422].includes(ex.response.status)) {
      throw ex;
    }
  }
};

async function richiediAuthcode(dominio: string) {
  if (!dominio) {
    return;
  }

  if (!confirm(_t("Sei sicuro di voler richiedere l'AuthCode per il dominio {0}?", [dominio]))) {
    return;
  }

  try {
    await new TicketResource().save({
      titolo: _t(`Richiesta AuthCode per il dominio {0}`, [dominio]),
      dettaglio: _t(`L'utente {0} ha richiesto l'AuthCode per il dominio {1} dalla pagina my hosting dell'area riservata`, [login.username, dominio]),
    });
  } catch (ex: any) {
    if (!ex.response || ![401, 404, 422].includes(ex.response.status)) {
      throw ex;
    }
  }

  const app = useAppStore();
  app.toast(_t('La richiesta è stata inviata ai nostri tecnici.'), 'success');
};
</script>
