<template>
  <x-input
    :model-value="modelValue"
    name="nome_server"
    type="hostname"
    required
    :placeholder="_t('Nome del server')"
    @update:model-value="emit('update:modelValue', String($event))"
    class="nome_server"
  />
</template>

<style>
.nome_server{
  max-width: 300px;
}
</style>

<script setup lang="ts">
import type { WizardInjection } from '../wizard.vue';
import XInput from '../../x-input.vue';
import { $t as _t } from '@/i18n';

type ValueType = NonNullable<WizardInjection['nomeServer']>;

defineProps<{
  modelValue: ValueType;
  required?: boolean;
}>();

const emit = defineEmits<{
  (name: `update:modelValue`, v: ValueType): void;
}>();
</script>
