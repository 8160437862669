<template>
  <div v-if="prezzo > 0" v-bind="ouiaProps">
    {{ title ?? defaultTitle }}:
    <s v-if="wizard.fatturaMe && prezzo !== prezzoScontato">
      {{ $currency(prezzo, wizard.customer?.currency) }}
    </s>
    &nbsp;
    <strong>
      {{ $currency(prezzoScontato, wizard.customer?.currency) }}
      <span v-if="wizard.customer === undefined || wizard.customer.vat > 0"> + {{ _t('IVA') }}</span> /
      {{ durataLabel }}
    </strong>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { $t as _t } from '../../../i18n';
import { useWizard } from '../composition';
import type { Durata, GroupedProducts } from '../wizard.vue';

defineOptions({
  name: 'WizardSummaryTotals',
});

const props = defineProps<OUIAProps & {
  durata: Durata,
  gruppo?: keyof GroupedProducts;
  title?: string;
}>();
const ouiaProps = useOUIAProps(props);

const wizard = useWizard();

const defaultTitle = computed(()  => props.durata === 'Mensile' ? _t('Totale ricorrente mensile'): _t('Totale ricorrente annuale'));
const durataLabel = computed(() => props.durata === 'Mensile' ? _t('Mese') : props.durata === 'Annuale' ? _t('Anno') : _t('Una tantum'));

const prezzo = computed(() => wizard.totaleByDurata(props.durata, false, props.gruppo));
const prezzoScontato = computed(() => wizard.totaleByDurata(props.durata, true, props.gruppo));
</script>
