<template>
  <div class="riepilogo-wrapper">
    <ul v-if="wizard.askLocation" >
      <wizard-summary-location />
    </ul>

    <ul v-if="wizard.askProdotti">
     <wizard-summary-option
        v-if="wizard.askOs && !wizard.askEssentialOs && !wizard.askCockpitConfig"
        :name="_t('Sistema operativo')"
        :value="wizard.os"
        editStep="WizardStepProdottiInfrastruttura"
        ouia-id="wizard-summary-infrastruttura-os"
      />

      <wizard-summary-option
        :name="wizard.path === 'cloud' ? _t('Risorse') : _t('Prodotto')"
        editStep="WizardStepProdottiInfrastruttura"
        ouia-id="wizard-summary-infrastruttura-prodotto"
      >
        <template v-if="wizard.path === 'cloud'">
          {{ wizard.cpuCloudQt }} <cpu-icon title="cpu" /> {{ wizard.ramCloudQt }} GB <memory-icon title="ram" /> {{ wizard.storageCloudQt }} GB <volume-icon title="storage" />
        </template>
        <strong v-else>
          {{ wizard.sceltaProdottoInfrastruttura }}
        </strong>
      </wizard-summary-option>

      <wizard-summary-option
        v-if="wizard.askStack"
        :name="_t('Stack')"
        editStep="WizardStepStack"
        ouia-id="wizard-summary-infrastruttura-stack"
      >
        <strong>{{ wizard.stack ?? _t('Nessuno') }} <template v-if="wizard.askEssentialOs">({{ wizard.essentialOs }})</template><template v-else-if="wizard.askCockpitConfig">({{ wizard.cockpitOs }} - {{ wizard.cockpitWs }})</template></strong>
      </wizard-summary-option>

      <wizard-summary-option
        v-if="wizard.askHardening"
        :name="_t('Hardening')"
        editStep="WizardStepHardening"
        ouia-id="wizard-summary-infrastruttura-hardening"
      >
        <strong>{{ wizard.hardening.length === 0 ? _t("Nessuno") : _t("Personalizzato") }}</strong>
        <form-help-tooltip v-if="wizard.hardening.length" :text="wizard.hardening.join(', ')" />
      </wizard-summary-option>
    </ul>
  </div>
</template>


<script lang="ts" setup>
import WizardSummaryLocation from './wizard-summary-location.vue';
import WizardSummaryOption from './wizard-summary-option.vue';
import CpuIcon from '@vue-patternfly/icons/cpu-icon';
import MemoryIcon from '@vue-patternfly/icons/memory-icon';
import VolumeIcon from '@vue-patternfly/icons/volume-icon';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import { useWizard } from '../composition';
import { $t as _t } from '@/i18n';

const wizard = useWizard();
</script>
