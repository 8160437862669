<template>
  <wizard-form v-if="!hidden" v-bind="ouiaProps" next-label="" confirm :title="$t('Riepilogo e preventivo')">
    <template v-if="wizard.productsCount === 0">
      <h3>{{ $t('Nessun prodotto trovato') }}</h3>
      <p>{{ $t('Con le opzioni da te selezionate non ci è al momento possibile generare un preventivo.') }}</p>
      <p>
        <i18n-t
          keypath="Puoi provare a modificare le opzioni scelte oppure contattare il nostro supporto commerciale {0}."
          tag="span"
        >
          <router-link :to="{ name: 'tickets' }">
            {{ $t('aprendo un ticket') }}
          </router-link>
        </i18n-t>
      </p>
    </template>

    <template v-else>
      <div class="question-wrapper">
        <div v-if="wizard.groupedProducts.dominio.items.length > 0 || wizard.askDomain" class="domino-summary-products">
          <h3>{{ $t('Registrazione dominio') }}</h3>
          <wizard-summary-dominio />
          <product-list
            :items="wizard.groupedProducts.dominio.items"
            :currency="wizard.customer?.currency ?? 'EUR'"
            :hide-plus-vat="wizard.hidePlusVat"
            :hide-price="wizard.freePeriod > 0"
          />
        </div>
        <div v-if="wizard.groupedProducts.infrastruttura.items.length > 0" class="infrastruttura-summary-products">
          <h3>{{ $t('Infrastruttura') }}</h3>
          <wizard-summary-infrastruttura />
          <product-list
            :items="wizard.groupedProducts.infrastruttura.items"
            :currency="wizard.customer?.currency ?? 'EUR'"
            :always-full-price="!wizard.fatturaMe"
            :hide-plus-vat="wizard.hidePlusVat"
            :hide-price="wizard.freePeriod > 0"
            :show-qt="wizard.showQt"
          />
          <div v-if="wizard.path === 'cloud' && !wizard.freePeriod" class="riepilogo-wrapper group-total">
            <wizard-summary-totals durata="Annuale" gruppo="infrastruttura" :title="$t('Costo totale annuale del server')"/>
            <wizard-summary-totals durata="Mensile" gruppo="infrastruttura" :title="$t('Costo totale mensile del server')"/>
          </div>
        </div>
        <div v-if="wizard.askFirewall">
          <h3>{{ $t('Firewall') }}</h3>
          <wizard-summary-firewall />
          <product-list
            :items="wizard.groupedProducts.firewall.items"
            :currency="wizard.customer?.currency ?? 'EUR'"
            :always-full-price="!wizard.fatturaMe"
            :hide-plus-vat="wizard.hidePlusVat"
            :hide-price="wizard.freePeriod > 0"
            :show-qt="wizard.showQt"
          />
          <div class="riepilogo-wrapper group-total" v-if="wizard.groupedProducts.firewall.items.length > 0 && !wizard.freePeriod">
            <wizard-summary-totals durata="Annuale" gruppo="firewall" :title="$t('Costo totale annuale del firewall')"/>
            <wizard-summary-totals durata="Mensile" gruppo="firewall" :title="$t('Costo totale mensile del firewall')"/>
          </div>
        </div>
        <div v-if="wizard.groupedProducts.mail.items.length > 0 || wizard.askMail" class="mail-summary-products">
          <h3 v-if="wizard.path === 'mail'">{{ $t('Email') }}</h3>
          <h3 v-else>{{ $t('Gestione email') }}</h3>
          <wizard-summary-email />
          <product-list
            :items="wizard.groupedProducts.mail.items"
            :currency="wizard.customer?.currency ?? 'EUR'"
            :always-full-price="!wizard.fatturaMe"
            :hide-plus-vat="wizard.hidePlusVat"
            :hide-price="wizard.freePeriod > 0"
          />
        </div>
        <div v-if="wizard.groupedProducts.addon.items.length > 0 || wizard.askSsl" class="other-summary-products">
          <h3 v-if="wizard.path === 'altro'">{{ $t('Servizi Aggiuntivi') }}</h3>
          <h3 v-else>{{ $t('Certificato SSL') }}</h3>
          <wizard-summary-ssl />
          <product-list
            :items="wizard.groupedProducts.addon.items"
            :currency="wizard.customer?.currency ?? 'EUR'"
            :always-full-price="!wizard.fatturaMe"
            :hide-plus-vat="wizard.hidePlusVat"
            :hide-price="wizard.freePeriod > 0"
          />
        </div>
      </div>
      <div class="titolo-note">
        <div>
          <h3>{{ $t('Titolo') }}</h3>
          <p>{{ $t('Se vuoi puoi dare un titolo all\'offerta:') }}</p>
          <x-input
            v-model="wizard.title"
            type="text"
            name="title"
            class="note-finali"
            :placeholder="wizard.defaultTitle"
          />
        </div>
        <div>
          <h3>{{ $t('Note') }}</h3>
          <p>{{ $t('Scrivici ogni tipo di nota o chiarimento relativo al progetto') }}</p>
          <textarea
            v-model="wizard.note"
            name="note"
            placeholder="Note Progetto"
            class="note-finali"
            rows="10"
          />
        </div>
      </div>

      <div id="totali" class="question-wrapper">
        <div
          v-if="(wizard.askClienti && wizard.customer) ||
            wizard.askDurataFatturazione ||
            (wizard.fatturaMe && !wizard.customer?.currency_locked)"
          class="question-wrapper"
        >
          <h3>{{ $t('Opzioni di fatturazione') }}</h3>
          <ul class="fatturazione-wrapper">
            <wizard-summary-option
              v-if="wizard.askClienti && wizard.customer"
              :name="$t('Offerta intestata a')"
              :value="wizard.customer.ragione_sociale"
              editStep="WizardStepClienti"
              ouia-id="wizard-summary-fattura-a"
            />
            <li v-if="wizard.askDurataFatturazione">
              {{ $t('Ricorrenza ') }}:
              <toggle
                v-model="wizard.fatturazioneAnnuale"
                :on-value="true"
                :off-value="false"
                :on-text="$t('Annuale')"
                :off-text="$t('Mensile')"
                off-class="btn-success"
              />
            </li>
            <li v-if="wizard.fatturaMe && !wizard.customer?.currency_locked">
              {{ $t('Valuta') }}:
              <currency-toggle id="currency-dropdown" />
            </li>
          </ul>
        </div>

        <wizard-summary-totals v-if="!wizard.freePeriod" durata="Annuale" />
        <wizard-summary-totals v-if="!wizard.freePeriod" durata="Mensile" />
        <div v-if="wizard.askTestingMode">
          <strong class="free-label">{{ $t('I primi {0} giorni sono gratuiti', [wizard.freePeriod]) }}</strong>
          <div>
            <toggle
              v-model="wizard.renewAfterFreePeriod"
              :on-value="true"
              :off-value="false"
              :on-text="$t('Rinnova dopo il periodo gratuito')"
              :off-text="$t('Disdici dopo il periodo gratuito')"
            />
          </div>
        </div>
        <wizard-summary-actions />
      </div>
    </template>
  </wizard-form>
</template>

<style lang="scss">
.riepilogo-wrapper {
  background-color: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 10px;
}

.riepilogo-wrapper ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 30px;
  margin-bottom: 0;
}
.fatturazione-wrapper {
  font-size: 14px;
  list-style: none;

  li {
    margin-bottom: 5px;
  }
}
.radio-action{
  text-align: right;
}
.note-finali {
  width: 500px;
}
@media (min-width: $screen-lg-min) {
  .riepilogo-wrapper ul > li {
    width: 25%;
  }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
  .riepilogo-wrapper ul > li {
    width: 50%;
  }
}
@media (max-width: $screen-xs-max) {
  .riepilogo-wrapper ul {
    flex-direction: column;
  }
}

.titolo-note {
  float: left;
}

.group-total{
  text-align: right;
  padding-right: 20px;
  margin-top: -20px;
}

#totali {
  font-size: 16px;
  text-align: right;
  float: right;
}
</style>

<script lang="ts" setup>
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import Toggle from '../../toggle.vue';
import CurrencyToggle from '../../currency-toggle.vue';
import ProductList from '../../product-list.vue';
import XInput from '@/components/x-input.vue';
import wizardSummaryOption from '../summary/wizard-summary-option.vue';
import WizardSummaryTotals from '../summary/wizard-summary-totals.vue';
import WizardSummaryActions from '../summary/wizard-summary-actions.vue';
import WizardSummaryDominio from '../summary/wizard-summary-dominio.vue';
import WizardSummaryInfrastruttura from '../summary/wizard-summary-infrastruttura.vue';
import WizardSummaryFirewall from '../summary/wizard-summary-firewall.vue';
import WizardSummaryEmail from '../summary/wizard-summary-email.vue';
import WizardSummarySsl from '../summary/wizard-summary-ssl.vue';
import WizardForm from '../wizard-form.vue';

defineOptions({
  name: 'WizardStepSummary',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep();

defineExpose({
  isEnabled,
});
</script>
