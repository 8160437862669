<template>
  <div class="row cloud-item">
    <div class="col-xs-4 col-sm-2"><cpu-icon /> {{ $t('CPU') }}:</div>
    <div class="col-xs-8 col-sm-2">
      <x-input
        type="number"
        name="cpuQtn"
        required
        :min="1"
        :max="64"
        append="Core"
        :model-value="cpuQtn"
        @update:model-value="emit('update:cpuQtn', Number($event))"
      />
    </div>
    <div class="col-xs-12 col-sm-4">
      <wizard-price-tag :product="wizard.getProdotto(wizard.codiciCloud.cpu)" :qt="cpuQtn"/>
    </div>
  </div>
  <div class="row cloud-item">
    <div class="col-xs-4 col-sm-2"><memory-icon /> {{ $t('RAM') }}:</div>
    <div class="col-xs-8 col-sm-2">
      <x-input
        type="number"
        name="ramQtn"
        required
        :min="1"
        :max="256"
        append="GB"
        :model-value="ramQtn"
        @update:model-value="emit('update:ramQtn', Number($event))"
      />
    </div>
    <div class="col-xs-12 col-sm-4">
      <wizard-price-tag :product="wizard.getProdotto(wizard.codiciCloud.ram)" :qt="ramQtn"/>
    </div>
  </div>
  <div class="row cloud-item">
    <div class="col-xs-4 col-sm-2"><volume-icon /> {{ $t('Storage') }}:</div>
    <div class="col-xs-8 col-sm-2">
      <x-input
        type="number"
        name="storageQtn"
        required
        :min="10"
        :max="5000"
        :step="10"
        append="GB"
        :model-value="storageQtn"
        @update:model-value="emit('update:storageQtn', Number($event))"
      />
    </div>
    <div class="col-xs-12 col-sm-4">
      <wizard-price-tag :product="wizard.getProdotto(wizard.codiciCloud.storage)" :qt="storageQtn"/>
    </div>
  </div>
  <div v-if="wizard.os === 'windows'" class="row cloud-item">
    <div class="col-xs-4 col-sm-2"><id-card-icon /> {{ $t('Licenza sistema operativo') }}:</div>
    <div class="col-xs-8 col-sm-2">
      <x-input
        type="number"
        disabled
        append="Core"
        :model-value="Math.round(cpuQtn)"
      />
    </div>
    <div class="col-xs-12 col-sm-4">
      <wizard-price-tag :product="wizard.getProdotto(wizard.codiciProdottiAddon.winServer)" :qt="Math.round(cpuQtn / 2)"/>
    </div>
  </div>
  <wizard-price-tag :product="wizard.serverCloud" :label="$t('Prezzo totale server')" />
</template>

<style lang="scss" scoped>
.cloud-item{
  margin-bottom: 10px;

  input {
    width: 50px;
  }
}
.nome_server{
  max-width: 300px;
}
</style>

<script setup lang="ts">
import XInput from '@/components/x-input.vue';
import WizardPriceTag from '../wizard-price-tag.vue';
import { useWizard } from '../composition';
import CpuIcon from '@vue-patternfly/icons/cpu-icon';
import MemoryIcon from '@vue-patternfly/icons/memory-icon';
import VolumeIcon from '@vue-patternfly/icons/volume-icon';
import IdCardIcon from '@vue-patternfly/icons/id-card-icon';

defineProps<{
  cpuQtn: number;
  ramQtn: number;
  storageQtn: number;
}>();

const emit = defineEmits<{
  (name: `update:${'cpu'|'ram'|'storage'}Qtn`, v: number): void;
}>();

const wizard = useWizard();
</script>
