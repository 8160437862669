<template>
  <div v-if="wizard.askMail" class="riepilogo-wrapper">
    <ul>
      <wizard-summary-location v-if="wizard.path === 'mail'" />

      <wizard-summary-option
        v-if="wizard.path !== 'mail' && wizard.codiceProdottoMail === ''"
        :name="_t('Servizio mail')"
        :value="_t('Non attivare')"
        editStep="WizardStepMail"
        ouia-id="wizard-summary-mail-attivazione"
      />

      <wizard-summary-option
        v-if="wizard.codiceProdottoMail"
        :name="_t('Prodotto')"
        :value="wizard.codiceProdottoMail"
        editStep="WizardStepMail"
        ouia-id="wizard-summary-mail-prodotto"
      />
    </ul>
  </div>
</template>


<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import WizardSummaryLocation from './wizard-summary-location.vue';
import { useWizard } from '../composition';
import { $t as _t } from '@/i18n';

const wizard = useWizard();
</script>
