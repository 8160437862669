<template>
  <div>
    <template v-if="currentStep === 'WizardStepScopo'">
      <p>{{ $t('Benvenuto nel wizard di Artera!') }}</p>
      <p>
        {{ $t('Grazie a questo strumento potrai strutturare un preventivo personalizzato in base alle tue esigenze.') }}
      </p>
    </template>
    <div class="wizard-pf-body">
      <div v-if="!ready" class="blank-slate-pf">
        <div class="spinner spinner-lg blank-slate-pf-icon" />
        <h3 class="blank-slate-pf-main-action">
          {{ $t('Stiamo caricando il wizard...') }}
        </h3>
        <p class="blank-slate-pf-secondary-action">
          {{ $t('Ancora pochi istanti e potrai costruire il tuo preventivo.') }}
        </p>
      </div>
      <template v-else>
        <wizard-step-scopo />
        <wizard-step-location />
        <wizard-step-prodotti-infrastruttura />
        <wizard-step-stack />
        <wizard-step-hardening />
        <wizard-step-mail />
        <wizard-step-ssl />
        <wizard-step-dominio />
        <wizard-step-clienti v-if="askClienti" />
        <wizard-step-summary />
        <wizard-step-thankyou />
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.question-wrapper {
  margin: 30px 0;
}
.question-wrapper p,
.step-description {
  max-width: 1000px;
}
.radio-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1000px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.radio-description {
  padding: 0 0 10px 16px;
}
@media (min-width: $screen-xs-max) {
  .radio-wrapper > li {
    width: 20%;
  }
}
@media (max-width: $screen-xs-max) {
  .radio-wrapper {
    flex-direction: column;
  }
}

.product-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.product-wrapper > div {
  width: 50%;
}

.product-column {
  margin-top: 25px;
}

@media (max-width: $screen-md-max) {
  .product-wrapper {
    flex-direction: column;
  }

  .product-wrapper > div {
    margin-bottom: 25px;
    width: 100%;
  }
}
</style>

<script lang="ts">
import { defineComponent, toValue, type PropType, type InjectionKey, computed } from 'vue';
import { asyncComputedList, type AsyncComputedStateObject } from '@common/asyncComputed';
import { provideChildrenTracker, useResettableState } from '@common/use';
import { by } from '@common/utils';
import { $t } from '../../i18n';
import { type NormalizedCatalogProduct } from '../../pages/catalog.vue';

import { WizardStepsTrackerSymbol } from './composition';
import WizardStepScopo from './steps/wizard-step-scopo.vue';
import WizardStepLocation from './steps/wizard-step-location.vue';
import WizardStepProdottiInfrastruttura from './steps/wizard-step-prodotti-infrastruttura.vue';
import WizardStepStack from './steps/wizard-step-stack.vue';
import WizardStepHardening from './steps/wizard-step-hardening.vue';
import WizardStepDominio from './steps/wizard-step-dominio.vue';
import WizardStepSsl from './steps/wizard-step-ssl.vue';
import WizardStepMail from './steps/wizard-step-mail.vue';
import WizardStepClienti from './steps/wizard-step-clienti.vue';
import WizardStepSummary from './steps/wizard-step-summary.vue';
import WizardStepThankyou from './steps/wizard-step-thankyou.vue';
import { useCompanyStore } from '@/store/company';
import { useLoginStore } from '@/store/login';
import { CompanyResource, MyCustomersResource, OffersResource, type Offer, type MyCustomersAccess } from '@/resources';
import { deployLocations, backupLocations } from '@/common/utils';
import { PRODOTTIORDINI_PROVISIONDB, PRODOTTIORDINI_PROVISIONPHP } from '@common/consts';;

export interface Path {
  label: string;
  description: string;
}

export interface Paths {
  hosting: Path;
  cloud: Path;
  reseller: Path;
  host: Path;
  dominio: Path | null;
  mail: Path | null;
  storage: Path | null;
  altro: Path | null;
}

export interface ProductCodesByInfraType {
  hosting: Record<OperativeSystems, string[]>;
  reseller: Record<'linux', string[]>;
  host: Record<'linux', string[]>;
  storage: Record<'linux', string[]>;
}

export interface CodiciCloud {
  cpu: string,
  ram: string,
  storage: string,
}

export interface GroupedProducts {
  dominio: {
    items: Product[];
  };
  infrastruttura: {
    items: Product[];
  };
  firewall: {
    items: Product[];
  };
  addon: {
    items: Product[];
  };
  mail: {
    items: Product[];
  };
}

export type Durata = 'Annuale'|'Mensile';
export type ProductPriceInfo = Pick<Product, 'nome' | 'prezzo' | 'prezzo_scontato' | 'durata' | 'utenti'>
export type OperativeSystems = 'linux'|'windows';
export type OpzioniDomainAction = 'registrazione' | 'dns' | undefined;
export type OpzioniStack = 'essential' | 'cockpit' | 'whm' | 'plesk linux' | 'plesk windows' | 'windows' | 'server mail dedicato' | 'mailarchive';
type OpzioniAction ='confirm' | 'pdf' | 'mailme' | 'mailcustomer';

const webServers = ['Apache', 'NGINX'] as const;
const cockpitOperativeSystems = ['AlmaLinux 9', 'AlmaLinux 8', 'Debian', 'Ubuntu'] as const;

const paths = {
  dominio: {
    label: $t('Solo dominio e DNS'),
    description: $t(
      'Per registrare il tuo nome a dominio, tra oltre 200 estensioni TLD. Il servizio include la gestione DNS e la possibilità di gestire il redirect.',
    ),
  },
  mail: {
    label: $t('Solo email (dominio opzionale)'),
    description: $t(
      'Per attivare il tuo servizio email personale sul tuo nome a dominio.',
    ),
  },
  hosting: {
    label: $t('Hosting e email (dominio opzionale)'),
    description:$t('Per attivare il tuo piano hosting su un server condiviso per il tuo sito internet. Include anche la gestione delle servio email, backup e certificato SSL.'),
  },
  reseller: {
    label: $t('Cloud Reseller'),
    description:$t('Per attivare il tuo piano reseller dedicato.'),
  },
  cloud: {
    label: $t('Cloud (dominio e email opzionali)'),
    description:$t('Per configurare il tuo cloud server dedicato al tuo sito internet, e-commerce o applicazione. Servizio ottimizzato sulla base delle tue esigenze.'),
  },
  host: {
    label: $t('Cloud host'),
    description:$t('Per configurare il tuo cloud host dedicato.'),
  },
  storage: {
    label: $t('Storage & Backup'),
    description: $t(
      'Per attivare subito spazio di archiviazione sicuro e ridondato per gestire i tuoi files e i backup dei dati critici personali ed aziendali.',
    ),
  },
  altro: {
    label: $t('Altro'),
    description: $t(
      'Licenze, certificati SSL, archiviatore email Atlas',
    ),
  },
};

const opzioniHardening = [
  'Akeneo',
  'Craft CMS',
  'Delphi',
  'Django',
  'Drupal',
  'Elasticsearch',
  'Ionic',
  'Joomla',
  'Kirby',
  'Laravel',
  'Magento 1',
  'Magento 2',
  'MongoDB',
  'Moodle',
  'MySQL',
  'Nextcloud + ONLYOFFICE',
  'Node.js',
  'Odoo',
  'ONLYOFFICE',
  'OroCommerce',
  'PHP',
  'PostgreSQL',
  'PrestaShop',
  'Python',
  'Ruby',
  'Rust',
  'Shopware',
  'Siberian',
  'Statmic',
  'Sulu',
  'Sylius',
  'Symphony',
  'TYPO3',
  'WooCommerce',
  'WordPress',
  'Wordpress multisite',
] as const;

const opzioniDb: Record<typeof PRODOTTIORDINI_PROVISIONDB[number], string> = {
  'best': $t('Migliore disponibile'),
  'mariadb': 'MariaDB',
  'mysql-8': 'MySQL 8',
  'mysql-57': 'MySQL 5.7',
};
const opzioniPhp: Record<typeof PRODOTTIORDINI_PROVISIONPHP[number], string> = {
  'best': $t('Migliore disponibile'),
  'v8.4': '8.4',
  'v8.3': '8.3',
  'v8.2': '8.2',
  'v8.1': '8.1',
  'v8.0': '8.0',
  'v7.4': '7.4',
  'v7.3': '7.3',
  'v7.2': '7.2',
  'v7.1': '7.1',
  'v7.0': '7.0',
  'v5.6': '5.6',
  'v5.5': '5.5',
  'v5.4': '5.4',
  'v5.3': '5.3',
  'v5.2': '5.2',
};

type InfoWizard = {
  action: string;
  title: string;
  nomeServer?: string;
  hardening?: string[];
  provision_location?: keyof typeof deployLocations;
  provision_backup_location?: keyof typeof backupLocations;
  note?: string;
  prodotti: {
    codice: string,
    qt: number,
    dominio: string,
    os?: string,
    webserver?: 'Apache'|'NGINX',
    provision_db?: typeof PRODOTTIORDINI_PROVISIONDB[number],
    provision_php?: typeof PRODOTTIORDINI_PROVISIONPHP[number],
  }[];
}

const codiciProdottiInfrastruttura: ProductCodesByInfraType = {
  hosting: {
    linux: ['HOSMN', 'HOSMRS', 'HOSVEN', 'HOSERT', 'HOSTITA'],
    windows: ['HOSBASW', 'HOSPREMWIN'],
  },
  reseller: {
    linux: ['HOSRESSIL', 'HOSRESGLD', 'HOSRES'],
  },
  host: {
    linux: ['HOSTCORE', 'HOSTGEN'],
  },
  storage: {
    linux: ['STOCOS', 'STOSMART', 'MLARCH100', 'STBCKACR', 'STBCKR1B', 'STBCKIBM', 'STBCKVEE',],
  },
};

const codiciCloud: CodiciCloud = {
  cpu: 'MAVCPU',
  ram: 'MAVRAM',
  storage: 'MAVSTO',
};

const cpuFirewallQt = 2;
const ramFirewallQt = 2;
const storageFirewallQt = 40

const codiciProdottiMail = ['MLART10Y', 'MLART20Y', 'MLART40Y', 'MLART60Y', 'MLART80Y', 'MLART100Y', 'MLART120'] as const;

const codiciProdottiAddon = {
  registrazionePremium: 'REGPREMY',
  ssl: ['SSLBAS', 'SSLART', 'SSLPREMY', 'SSLWLD'],
  imunify360: ['AHSIM3601', 'AHSIM36030', 'AHSIM360250', 'AHSIM360500'],
  liteSpeed: 'AHSLITE',
  winServer: 'CLDWINSTD',
  terminalServer: 'CLDRD',
  soloDNS: 'HOSDOM',
  atlas: 'MLARCH100',
  supplierFirewall: 'CLOUDAPPVPN',
};
const codiciProdottiSql = ['CLDSQS', 'CLDSQW', 'CLDSQE'] as const;

export type StackInfo = Record<OpzioniStack, {
  codici: string[],
  descrizione: string,
  minRam: number,
  os: OperativeSystems[],
}>;

const stacks: StackInfo = {
  essential: {
    codici: ['AHSESS'],
    descrizione: $t("Questo stack include il monitoraggio essenziale dei parametri vitali e i backup di sicurezza giornalieri, assicurandoti tranquillità e prestazioni stabili per le tue applicazioni."),
    minRam: 1,
    os: ['linux', 'windows'],
  },
  cockpit: {
    codici: ['AHSCCKP'],
    descrizione: $t("Con NGINX, Percona Server for MySQL, Redis e il pannello Cockpit, questo stack offre prestazioni elevate e una gestione semplificata. Include sonde avanzate per un monitoraggio proattivo e gestione personalizzata dei backup, garantendo massima sicurezza e reattività."),
    minRam: 1,
    os: ['linux'],
  },
  whm: {
    codici: ['AHSWHM1', 'AHSWHM5', 'AHSWHM30', 'AHSWHM100', 'AHSWHM250', 'AHSWHM500'],
    descrizione: $t("Perfetto per l'hosting reseller, combina CloudLinux OS e WHM / cPanel per una gestione avanzata. Include supporto specialistico e tutte le funzionalità avanzate del cloud hosting Artera, rendendolo ideale per chi cerca una soluzione completa e affidabile."),
    minRam: 8,
    os: ['linux'],
  },
  'plesk linux': {
    codici: ['AHSPLK1', 'AHSPLK30', 'AHSPLK500'],
    descrizione: $t("Questo stack fornisce un pannello di controllo intuitivo con Plesk per Linux, ottimizzato per la gestione di siti web, applicazioni e server email. Offre strumenti avanzati e supporto specialistico, ideale per professionisti e agenzie che desiderano efficienza."),
    minRam: 1,
    os: ['linux'],
  },
  'plesk windows': {
    codici: ['AHSPLKW1', 'AHSPLKW30', 'AHSPLKW500'],
    descrizione: $t("Questo stack fornisce un pannello di controllo intuitivo con Plesk per Windows, ottimizzato per la gestione di siti web, applicazioni e server email. Offre strumenti avanzati e supporto specialistico, ideale per professionisti e agenzie che desiderano efficienza."),
    minRam: 4,
    os: ['windows'],
  },
  windows: {
    codici: ['AHSWIN'],
    descrizione: $t("Questo stack ti permette di avere il tuo server Windows completamente gestito."),
    minRam: 4,
    os: ['windows'],
  },
  'server mail dedicato': {
    codici: ['AHSDEDMAILY'],
    descrizione: $t("Questo stack ti permette di attivare un server mail dedicato"),
    minRam: 1,
    os: ['linux'],
  },
  mailarchive: {
    codici: ['AHSATLAS'],
    descrizione: $t("Attiva un server di archiviazione email privato con un potente motore di ricerca. Atlas Mail Archive è perfetto per archiviare e recuperare facilmente tutte le email aziendali, garantendo sicurezza e conformità normativa nel flusso di comunicazioni."),
    minRam: 1,
    os: ['linux'],
  },
};

export type Product = NormalizedCatalogProduct & {
  codice_wizard: string;
  dominio: string;
  qt?: number;
  os?: string;
  webserver?: 'Apache' | 'NGINX';
  provision_db?: typeof PRODOTTIORDINI_PROVISIONDB[number];
  provision_php?: typeof PRODOTTIORDINI_PROVISIONPHP[number];
};

const initialStep = "WizardStepScopo";

export type WizardInjection = {
  readonly webServers: typeof webServers;
  readonly cockpitOperativeSystems: typeof cockpitOperativeSystems;
  readonly paths: Paths;
  readonly codiceProdottoDominio: string;
  readonly codiciProdottiAddon: typeof codiciProdottiAddon;
  readonly codiciProdottiMail: typeof codiciProdottiMail;
  readonly codiciProdottiSql: typeof codiciProdottiSql;
  readonly stacks: StackInfo;
  readonly codiciCloud: CodiciCloud;
  readonly codiceProdottoImunify: string;
  readonly dominioRegistrabile: boolean;
  readonly askClienti: boolean;
  readonly customers: MyCustomersAccess[];
  readonly customer: MyCustomersAccess | undefined;
  readonly askStack: boolean;
  readonly askFirewall: boolean;
  readonly askNomeServer: boolean;
  readonly askSsl: boolean;
  readonly askAddonCpanel: boolean;
  readonly askAddonWindows: boolean;
  readonly askMail: boolean;
  readonly askOs: boolean;
  readonly askEssentialOs: boolean;
  readonly askCockpitConfig: boolean;
  readonly askHardening: boolean;
  readonly askDb: boolean;
  readonly askPhp: boolean;
  readonly askDomain: boolean;
  readonly askLocation: boolean;
  readonly askLocationBackup: boolean;
  readonly askProdotti: boolean;
  readonly askStorageQt: boolean;
  readonly showQt: boolean;
  readonly askRegistrazione: boolean;
  readonly askDurataFatturazione: boolean;
  readonly askTestingMode: boolean;
  readonly hidePlusVat: boolean;
  readonly deployLocations: typeof deployLocations;
  readonly backupLocations: typeof backupLocations;
  readonly registraDominio: boolean;
  readonly codiceProdottoSla: string;
  readonly codiceProdottoStack: string | undefined;
  readonly availableDomain: boolean | null | undefined;
  readonly searchedDomain: string | null | undefined;
  readonly freePeriod: number;
  readonly groupedProducts: GroupedProducts;
  readonly productsCount: number;
  readonly defaultTitle: string;
  readonly canConfirmOffer: boolean;
  readonly durata: Durata;
  readonly serverCloud: ProductPriceInfo;
  readonly firewall: ProductPriceInfo;
  readonly cpuFirewallQt: number;
  readonly ramFirewallQt: number;
  readonly storageFirewallQt: number;
  readonly initialStep: string;
  currentStep: string;
  opzioniHardening: typeof opzioniHardening;
  opzioniDb: typeof opzioniDb;
  filteredOpzioniPhp: typeof opzioniPhp;
  db: typeof PRODOTTIORDINI_PROVISIONDB[number] | undefined;
  php: typeof PRODOTTIORDINI_PROVISIONPHP[number] | undefined;
  path: keyof Paths | undefined;
  sceltaLocationDeploy: keyof typeof deployLocations | undefined;
  sceltaLocationBackup: keyof typeof backupLocations | undefined;
  fatturaMe: boolean;
  addonSla: boolean;
  addonWebAccelerator: boolean;
  addonLitespeed: boolean;
  addonImunify: boolean;
  addonTerminalServer: boolean;
  addonAtlas: boolean;
  addOnFirewall: boolean;
  domainAction: 'registrazione' | 'dns' | undefined;
  registrazionePremium: boolean;
  idClienteFinale: number | undefined;
  codiceSsl: string;
  codiceProdottoMail: typeof codiciProdottiMail[number] | '';
  codiceLicenzaSql: typeof codiciProdottiSql[number] | '';
  stack: OpzioniStack | null | undefined;
  os: OperativeSystems;
  essentialOs: string | undefined;
  cockpitOs: string | undefined;
  cockpitWs: typeof webServers[number] | undefined;
  sceltaProdottoInfrastruttura: string;
  nomeServer: string;
  cpuCloudQt: number;
  ramCloudQt: number;
  storageCloudQt: number;
  storageQt: number;
  codiceProdottoNextcloud: string;
  nomeDominio: string;
  fatturazioneAnnuale: boolean;
  renewAfterFreePeriod: boolean;
  hardening: (typeof opzioniHardening[number])[];
  action: OpzioniAction | undefined;
  offertaNr: string | number | null;
  title: string | undefined;
  note: string | undefined;
  sending: boolean;
  getProdotto: (codici: string) => Product;
  getProdotti: (codici: readonly string[]) => Product[];
  tldRegistrabile: (domain: string) => boolean;
  totaleByDurata: (durata: Durata, scontato: boolean, group?: keyof GroupedProducts) => number;
  minPrice: (codici: string[]) => Product;
  goToStep: (step: string) => void;
  attiva: (value: boolean) => string;
  siNo: (value: boolean) => string;
  submit: (action: OpzioniAction) => Promise<void>;
  nextStep: () => void;
  previousStep: () => void;
  resetWizard: (dontAsk?: boolean) => void;
  opzioniProdottiInfrastruttura: () => string[];
  customersState: AsyncComputedStateObject;
};

export const WizardInjectionKey = Symbol('WizardInjectionKey') as InjectionKey<WizardInjection>;

export default defineComponent({
  name: 'Wizard',

  components: {
    WizardStepScopo,
    WizardStepLocation,
    WizardStepProdottiInfrastruttura,
    WizardStepStack,
    WizardStepHardening,
    WizardStepDominio,
    WizardStepSsl,
    WizardStepMail,
    WizardStepClienti,
    WizardStepSummary,
    WizardStepThankyou,
  },

  provide() {
    return {
      [WizardInjectionKey as symbol]: this,
    };
  },

  props: {
    freePeriod: {
      type: Number,
      default: 0,
    },
    searchedDomain: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    availableDomain: {
      type: Boolean as PropType<boolean | null | undefined>,
      default: null,
    },
  },

  setup(props) {
    const login = useLoginStore();
    const steps = provideChildrenTracker(WizardStepsTrackerSymbol);

    const initialState: Pick<WizardInjection,
        'initialStep'
      | 'currentStep'
      | 'path'
      | 'hardening'
      | 'db'
      | 'php'
      | 'sceltaLocationDeploy'
      | 'sceltaLocationBackup'
      | 'os'
      | 'stack'
      | 'essentialOs'
      | 'cockpitOs'
      | 'cockpitWs'
      | 'sceltaProdottoInfrastruttura'
      | 'nomeServer'
      | 'cpuCloudQt'
      | 'ramCloudQt'
      | 'storageCloudQt'
      | 'storageQt'
      | 'domainAction'
      | 'nomeDominio'
      | 'registrazionePremium'
      | 'codiceProdottoMail'
      | 'codiceLicenzaSql'
      | 'addonWebAccelerator'
      | 'addonLitespeed'
      | 'addonImunify'
      | 'addonTerminalServer'
      | 'addonAtlas'
      | 'addOnFirewall'
      | 'codiceProdottoStack'
      | 'fatturaMe'
      | 'idClienteFinale'
      | 'renewAfterFreePeriod'
      | 'fatturazioneAnnuale'
      | 'action'
      | 'codiceSsl'
      | 'title'
      | 'note'
      | 'offertaNr'
    > = {
      initialStep: initialStep,
      currentStep: initialStep,
      path: undefined,
      hardening: [],
      db: 'best',
      php: 'best',
      sceltaLocationDeploy: undefined,
      sceltaLocationBackup: undefined,
      stack: undefined,
      os: 'linux',
      essentialOs: undefined,
      cockpitOs: undefined,
      cockpitWs: undefined,
      sceltaProdottoInfrastruttura: 'HOSMN',
      nomeServer: '',
      cpuCloudQt: 1,
      ramCloudQt: 1,
      storageCloudQt: 10,
      storageQt: 1,
      domainAction: undefined,
      nomeDominio: props.searchedDomain ?? '',
      registrazionePremium: false,
      codiceProdottoMail: '',
      codiceLicenzaSql: '',
      addonWebAccelerator: false,
      addonLitespeed: false,
      addonImunify: false,
      addonTerminalServer: false,
      addonAtlas: false,
      addOnFirewall: false,
      codiceProdottoStack: undefined,
      fatturaMe: true,
      idClienteFinale: undefined,
      fatturazioneAnnuale: true,
      renewAfterFreePeriod: true,
      action: undefined,
      codiceSsl: '',
      title: undefined,
      note: undefined,
      offertaNr: null,
    };

    const { state: resettableState, reset: setDefault } = useResettableState(initialState);

    const { state: customersState, result: customers } = asyncComputedList(async () => {
      if (!resettableState.fatturaMe.value) {
        return await new MyCustomersResource().get();
      }
      return [];
    });

    const customer = computed((): MyCustomersAccess | undefined => {
      if (!resettableState.fatturaMe.value && resettableState.idClienteFinale.value) {
        return customers.value.find(c => c.id == resettableState.idClienteFinale.value);
      }

      return {
        id: Number(login.id_azienda),
        ragione_sociale: login.azienda,
        currency: login.currency,
        currency_locked: login.currency_locked,
        vat: login.iva,
        enable_orders: false,
        indirizzo: null,
        provincia: null,
        cap: null,
        citta: null,
        stato: 'CH',
        presented: false,
        partita_iva: null,
        contact_name: null,
        contact_mail: null,
      };
    });

    const customerId = computed(() => customer.value?.id);

    const { state: productsState, result: products } = asyncComputedList(async () => {
      if (customerId.value) {
        return new CompanyResource().priceList(customerId.value).get();
      }
      return [];
    });

    const ready = computed(() => productsState.state === 'success');

    return {
      login,
      steps,
      ...resettableState,
      setDefault,
      products,
      customers,
      customersState,
      customer,
      ready,
    };
  },

  data() {
    return {
      sending: false,
      webServers,
      cockpitOperativeSystems,
      paths,
      codiciCloud,
      codiciProdottiMail,
      codiciProdottiSql,
      codiciProdottiAddon,
      stacks,
      opzioniHardening,
      opzioniDb,
      opzioniPhp,
      deployLocations,
      backupLocations,
      cpuFirewallQt,
      ramFirewallQt,
      storageFirewallQt,
    };
  },

  computed: {
    durata(): Durata {
      return this.fatturazioneAnnuale ? 'Annuale' : 'Mensile';
    },

    askOs() {
      return (this.path === 'hosting' || this.path === 'cloud');
    },

    askEssentialOs() {
      return this.askStack && this.stack === 'essential' && this.os === 'linux';
    },

    askCockpitConfig() {
      return this.askStack && this.stack === 'cockpit';
    },

    askHardening() {
      return (this.path === 'hosting' || this.path === 'cloud');
    },

    askLocation() {
      return this.path !== 'dominio' && this.path !== 'altro';
    },

    askLocationBackup() {
      return this.path !== 'dominio'  && this.path !== 'altro' && this.path !== 'storage';
    },

    askProdotti() {
      return this.path !== 'dominio' && this.path !== 'altro' && this.path !== 'mail';
    },

    askStorageQt() {
      return this.path === 'storage';
    },

    askDomain() {
      return (this.path !== 'cloud' || (this.askMail && this.codiceProdottoMail)) && this.path !== 'host';
    },

    showQt() {
      return this.askStorageQt || this.path === 'cloud';
    },

    askRegistrazione() {
      return !['dominio', 'storage'].includes(this.path ?? '');
    },

    askSsl() {
      return this.path !== 'dominio' && this.path !== 'reseller' && (this.path !== 'cloud' || (this.askMail && this.codiceProdottoMail)) && this.path !== 'host';
    },

    askAddonCpanel() {
      return this.path === 'cloud' && this.stack === 'whm';
    },

    askAddonWindows() {
      return this.path === 'cloud' && this.stack === 'windows';
    },

    askStack() {
      return this.path === 'cloud';
    },

    askFirewall() {
      return this.path === 'cloud' || this.path === 'host';
    },

    askNomeServer() {
      return this.path === 'cloud' || this.path === 'host';
    },

    askDb() {
      return this.path === 'hosting' || this.askStack && this.stack && ['essential', 'cockpit', 'whm', 'plesk linux', 'plesk windows', 'windows'].includes(this.stack);
    },

    askPhp() {
      return this.path === 'hosting' || this.askStack && this.stack && ['essential', 'cockpit', 'whm', 'plesk linux', 'plesk windows', 'windows'].includes(this.stack);
    },

    askTestingMode() {
      return this.freePeriod > 0;
    },

    hidePlusVat() {
      if (this.fatturaMe && this.login.iva < 0.01) {
        return true;
      }
      if (this.customer && this.customer.vat < 0.01) {
        return true;
      }

      return false;
    },

    askMail() {
      return (this.path === 'cloud' && (!this.stack || this.stack === 'essential' || this.stack === 'cockpit' || this.stack === 'windows')) || this.path === 'mail' || (this.path === 'hosting' && this.os === 'windows');
    },

    askClienti() {
      return this.login.is_reseller;
    },

    askDurataFatturazione() {
      return this.path !== 'dominio' && this.path !== 'mail';
    },

    registraDominio() {
      return ((this.searchedDomain && this.searchedDomain === this.nomeDominio ) || this.path === 'dominio' || this.domainAction === 'registrazione') && !!this.nomeDominio;
    },

    filteredOpzioniPhp() {
      if (this.askPhp && this.stack === "cockpit" && this.cockpitOs === 'AlmaLinux 9') {
        return Object.fromEntries(
          Object.entries(this.opzioniPhp).filter(([key]) => key >= 'v7.4' || key === 'best')
        );
      }
      if (this.askPhp && this.stack === "cockpit" && this.cockpitOs === 'AlmaLinux 8') {
        return Object.fromEntries(
          Object.entries(this.opzioniPhp).filter(([key]) => key >= 'v5.4' || key === 'best')
        );
      }

      return this.opzioniPhp;
    },

    estensioneDominio() {
      const tld = this.tldRegistrabile(this.nomeDominio);
      if (tld !== false) {
        return tld;
      }

      const v = this.nomeDominio.split('.');
      return v[v.length - 1];
    },

    tldRegistrabili() {
      const tld: Record<string, string> = {};
      const domini = this.products.filter(p => p.tld);
      for (const dom of domini) {
        if (dom.tld) {
          tld[dom.tld] = dom.codice;
        }
      }
      return tld;
    },

    dominioRegistrabile() {
      return this.tldRegistrabile(this.nomeDominio) !== false;
    },

    codiceProdottoDominio() {
      return this.tldRegistrabili[this.estensioneDominio];
    },

    codiceProdottoImunify() {
      switch (this.codiceProdottoStack) {
        case 'AHSWHM1': return 'AHSIM3601';
          break;
        case 'AHSWHM5': return 'AHSIM36030';
        case 'AHSWHM30': return 'AHSIM36030';
          break;
        case 'AHSWHM100': return 'AHSIM360250';
        case 'AHSWHM250': return 'AHSIM360250';
          break;
        case 'AHSWHM500': return 'AHSIM360500';
        default: return 'AHSIM3601';
      };
    },

    canConfirmOffer() {
      return this.fatturaMe ? this.login.enable_orders : this.customer?.enable_orders ?? false;
    },

    defaultTitle() {
      return $t('Offerta per servizi internet - {0}', [this.askDomain ? this.nomeDominio : this.nomeServer]);
    },

    productsCount() {
      let c = 0;
      for (const i of Object.values(this.groupedProducts)) {
        c += i.items.length;
      }
      return c;
    },

    serverCloud() {
      const prezzo =
        this.getProdotto(this.codiciCloud.cpu).prezzo * this.cpuCloudQt +
        this.getProdotto(this.codiciCloud.ram).prezzo * this.ramCloudQt +
        this.getProdotto(this.codiciCloud.storage).prezzo * this.storageCloudQt +
        (this.os === 'windows' ? this.getProdotto(this.codiciProdottiAddon.winServer).prezzo * Math.round(this.cpuCloudQt / 2) : 0);

      const prezzo_scontato =
        this.getProdotto(this.codiciCloud.cpu).prezzo_scontato * this.cpuCloudQt +
        this.getProdotto(this.codiciCloud.ram).prezzo_scontato * this.ramCloudQt +
        this.getProdotto(this.codiciCloud.storage).prezzo_scontato * this.storageCloudQt +
        (this.os === 'windows' ? this.getProdotto(this.codiciProdottiAddon.winServer).prezzo_scontato * Math.round(this.cpuCloudQt / 2) : 0);

      return {
        nome: 'Server personalizzato',
        prezzo,
        prezzo_scontato,
        durata: this.durata,
      }
    },

    firewall() {
      if (this.path === 'host') {
        return this.getProdotto(this.codiciProdottiAddon.supplierFirewall);
      }
      const prezzo =
        this.getProdotto(this.codiciCloud.cpu).prezzo * cpuFirewallQt +
        this.getProdotto(this.codiciCloud.ram).prezzo * ramFirewallQt +
        this.getProdotto(this.codiciCloud.storage).prezzo * storageFirewallQt +
        this.getProdotto(this.stacks.essential.codici[0]).prezzo;

      const prezzo_scontato =
        this.getProdotto(this.codiciCloud.cpu).prezzo_scontato * cpuFirewallQt +
        this.getProdotto(this.codiciCloud.ram).prezzo_scontato * ramFirewallQt +
        this.getProdotto(this.codiciCloud.storage).prezzo_scontato * storageFirewallQt +
        this.getProdotto(this.stacks.essential.codici[0]).prezzo_scontato;

      return {
        nome: 'Firewall Cloud',
        prezzo,
        prezzo_scontato,
        durata: this.durata,
      }
    },

    groupedProducts() {
      const prodotti: GroupedProducts = {
        dominio: {
          items: [],
        },
        infrastruttura: {
          items: [],
        },
        firewall: {
          items: [],
        },
        addon: {
          items: [],
        },
        mail: {
          items: [],
        },
      };

      // Prodotti dominio

      if (this.registraDominio && this.dominioRegistrabile) {
        prodotti.dominio.items.push({
          ...this.getProdotto(this.codiceProdottoDominio),
          dominio: this.nomeDominio,
        });

        if (this.registrazionePremium) {
          prodotti.dominio.items.push({
            ...this.getProdotto(this.codiciProdottiAddon.registrazionePremium),
            dominio: this.nomeDominio,
            });
        }
      } else if (this.domainAction === 'dns') {
        prodotti.dominio.items.push({
          ...this.getProdotto(this.codiciProdottiAddon.soloDNS),
          dominio: this.nomeDominio,
        });
      }

      // Prodotti infrastruttura
      if (this.askProdotti && this.path !== 'cloud' && this.sceltaProdottoInfrastruttura) {
        prodotti.infrastruttura.items.push({
          ...this.getProdotto(this.sceltaProdottoInfrastruttura),
          qt: this.askStorageQt ? this.storageQt : 1,
          dominio: this.askNomeServer ? `${this.nomeServer}.artera.farm` : this.nomeDominio,
          provision_db: this.askDb ? this.db : undefined,
          provision_php: this.askPhp ? this.php : undefined,
        });
      } else if (this.askProdotti && this.path === 'cloud') {
        prodotti.infrastruttura.items.push({
          ...this.getProdotto(codiciCloud.cpu),
          qt: this.cpuCloudQt,
          dominio: `${this.nomeServer}.artera.farm`,
        });
        prodotti.infrastruttura.items.push({
          ...this.getProdotto(codiciCloud.ram),
          qt: this.ramCloudQt,
          dominio: `${this.nomeServer}.artera.farm`,
        });
        prodotti.infrastruttura.items.push({
          ...this.getProdotto(codiciCloud.storage),
          qt: this.storageCloudQt,
          dominio: `${this.nomeServer}.artera.farm`,
        });

        if (this.os === 'windows') {
          prodotti.infrastruttura.items.push({
            ...this.getProdotto(this.codiciProdottiAddon.winServer),
            dominio: `${this.nomeServer}.artera.farm`,
            qt: Math.round(this.cpuCloudQt / 2),
          });
        }

        if (this.askStack && this.codiceProdottoStack) {
          const stack = {
            ...this.getProdotto(this.codiceProdottoStack),
            dominio: `${this.nomeServer}.artera.farm`,
          }
          if (this.askEssentialOs && this.essentialOs) {
            stack.os = this.essentialOs;
          } else if (this.askCockpitConfig && this.cockpitWs && this.cockpitOs) {
            stack.os = this.cockpitOs;
            stack.webserver = this.cockpitWs;
          } else if (this.askOs) {
            stack.os = this.os;
          }
          stack.provision_db = this.askDb ? this.db : undefined,
          stack.provision_php = this.askPhp ? this.php : undefined

          prodotti.infrastruttura.items.push(stack);
        }

        if (this.askAddonCpanel) {
          if (this.addonImunify && this.codiceProdottoImunify) {
            prodotti.infrastruttura.items.push({
              ...this.getProdotto(this.codiceProdottoImunify),
              dominio: `${this.nomeServer}.artera.farm`,
            });
          }
          if (this.addonLitespeed) {
            prodotti.infrastruttura.items.push({
              ...this.getProdotto(this.codiciProdottiAddon.liteSpeed),
              dominio: `${this.nomeServer}.artera.farm`,
            });
          }
        }

        if (this.askAddonWindows) {
          if (this.addonTerminalServer) {
            prodotti.infrastruttura.items.push({
              ...this.getProdotto(this.codiciProdottiAddon.terminalServer),
              dominio: `${this.nomeServer}.artera.farm`,
            });
          }

          if (this.codiceLicenzaSql) {
            prodotti.infrastruttura.items.push({
              ...this.getProdotto(this.codiceLicenzaSql),
              qt: Math.round(this.cpuCloudQt / 2),
              dominio: `${this.nomeServer}.artera.farm`,
            });
          }
        }
      }

      // Se sto comprando un host devo aggiugnere per forza un cockpit
      if (this.path === 'host') {
        prodotti.infrastruttura.items.push({
          ...this.getProdotto(this.stacks.cockpit.codici[0]),
          dominio: `${this.nomeServer}.artera.farm`,
        });
      }

      if (this.askFirewall && this.addOnFirewall) {
        if (this.path === 'host') {
          prodotti.firewall.items.push({
            ...this.getProdotto(this.codiciProdottiAddon.supplierFirewall),
            dominio: `${this.nomeServer}-firewall.artera.farm`,
          });
        } else {
          prodotti.firewall.items.push({
            ...this.getProdotto(codiciCloud.cpu),
            qt: cpuFirewallQt,
            dominio: `${this.nomeServer}-firewall.artera.farm`,
          });
          prodotti.firewall.items.push({
            ...this.getProdotto(codiciCloud.ram),
            qt: ramFirewallQt,
            dominio: `${this.nomeServer}-firewall.artera.farm`,
          });
          prodotti.firewall.items.push({
            ...this.getProdotto(codiciCloud.storage),
            qt: storageFirewallQt,
            dominio: `${this.nomeServer}-firewall.artera.farm`,
          });
          prodotti.firewall.items.push({
            ...this.getProdotto(this.stacks.essential.codici[0]),
            dominio: `${this.nomeServer}-firewall.artera.farm`,
            os: 'OPNSense',
          });
        }
      }

      // Prodotti AddOn
      if (this.askSsl && this.codiceSsl) {
        prodotti.addon.items.push({
          ...this.getProdotto(this.codiceSsl),
          dominio: this.nomeDominio,
        }); // Prodotto unicamente annuale
      }

      if (this.path === 'altro' && this.addonTerminalServer) {
        prodotti.addon.items.push({
          ...this.getProdotto(this.codiciProdottiAddon.terminalServer),
          dominio: this.nomeDominio,
        });
      }

      if (this.path === 'altro' && this.addonAtlas) {
        prodotti.addon.items.push({
          ...this.getProdotto(this.codiciProdottiAddon.atlas),
          dominio: this.nomeDominio,
        });
      }

      // Prodotti mail
      if (this.askMail && this.codiceProdottoMail) {
        prodotti.mail.items.push({
          ...this.getProdotto(this.codiceProdottoMail),
          dominio: this.nomeDominio,
        });
      }

      return prodotti;
    },
  },

  watch: {
    fatturaMe(v) {
      if (v) {
        this.idClienteFinale = undefined;
      }
    },

    stack(v: OpzioniStack) {
      if (this.askStack && v) {
        if (stacks[v].codici.length === 1) {
          this.codiceProdottoStack = stacks[v].codici[0];
        } else {
          this.codiceProdottoStack = undefined;
        }
      } else {
        this.codiceProdottoStack = undefined;
      }
    }
  },

  mounted() {
    this.setDefault();
  },

  methods: {
    opzioniProdottiInfrastruttura() {
      if (!this.path || this.path === 'dominio' || this.path === 'cloud' || this.path === 'mail' || this.path === 'altro') {
        return [];
      }

      if (this.path === 'storage' || this.path === 'reseller' || this.path === 'host') {
        return codiciProdottiInfrastruttura[this.path]['linux'];
      }

      return codiciProdottiInfrastruttura[this.path][this.os];
    },

    tldRegistrabile(domain: string) {
      for (const tld of Object.keys(this.tldRegistrabili).sort(by(s => s.length, true))) {
        if (domain.endsWith(`.${tld}`)) {
          return tld;
        }
      }
      return false;
    },

    nextStep() {
      for (
        let i = this.steps.findIndex(x => x.$options.name === this.currentStep) + 1;
        i < this.steps.length;
        i++
      ) {
        const step = this.steps[i];
        const enabled = step.isEnabled ?? toValue(step.$.exposed?.isEnabled);
        if (enabled && step.$options.name) {
          this.currentStep = step.$options.name;
          return;
        }
      }
    },

    previousStep() {
      for (let i = this.steps.findIndex(x => x.$options.name === this.currentStep) - 1; i >= 0; i--) {
        const step = this.steps[i];
        const enabled = step.isEnabled ?? toValue(step.$.exposed?.isEnabled);
        if (enabled && step.$options.name) {
          this.currentStep = step.$options.name;
          return;
        }
      }
    },

    resetWizard(dontAsk: boolean) {
      const r = dontAsk || confirm($t('Sei sicuro di voler resettare tutte le tue scelte e tornare al primo step?'));
      if (r) {
        this.setDefault();
      }
    },

    getProdotto(codice: string) {
      if (this.products) {
        const prodotto = this.products.find(
          p => (p.group_code === codice && p.durata === this.durata) || p.codice === codice,
        ) as Product;
        if (prodotto) {
          prodotto.prezzo = this.customer?.currency === 'EUR' ? prodotto.prezzo_eur : prodotto.prezzo_chf;
          prodotto.prezzo_scontato = this.customer?.currency === 'EUR' ? prodotto.prezzo_scontato_eur : prodotto.prezzo_scontato_chf;
          prodotto.prezzo_customer = this.login.currency == 'EUR' ? prodotto.prezzo_clienti_eur : prodotto.prezzo_clienti_chf,
          prodotto.codice_wizard = codice;
          return prodotto;
        }
      }
      return { codice } as Product;
    },

    getProdotti(codici: readonly string[]) {
      const prodotti = [];
      for (const codice of codici) {
        prodotti.push(this.getProdotto(codice));
      }
      return prodotti;
    },

    minPrice(codici: string[]) {
      const prodotti = this.getProdotti(codici);
      return prodotti.reduce((a, b) => a.prezzo_scontato < b.prezzo_scontato ? a : b);
    },

    totaleByDurata(durata: Durata, scontato: boolean, group?: keyof GroupedProducts) {
      const products = !!group ?
        this.groupedProducts[group].items :
        Object.values(this.groupedProducts)
          .flatMap(c => c.items) as Product[];

      return products
          .filter(p => p.durata === durata)
          .reduce((t, p) => t + ((this.fatturaMe && scontato ? p.prezzo_scontato : p.prezzo) * (p.qt ?? 1)), 0);
    },

    goToStep(step: string) {
      this.currentStep = step;
    },

    attiva(value: boolean) {
      return value ? $t('Attiva') : $t('Non attiva');
    },

    siNo(value: boolean) {
      return value ? $t('Sì') : $t('No');
    },

    createOffer(action: OpzioniAction) {
      if (!this.login.id_azienda || !this.customer) {
        return;
      }

      let id_cliente;
      if (!this.fatturaMe) {
        if (!this.idClienteFinale) {
          throw new Error('Nessun cliente finale selezionato');
        }
        id_cliente = this.idClienteFinale;
      } else {
        id_cliente = this.login.id_azienda;
      }

      const data: Partial<Offer> & InfoWizard = {
        action: action,
        currency: this.customer.currency,
        title: this.title ?? this.defaultTitle,
        free_period: this.freePeriod,
        id_azienda: Number(id_cliente),
        prodotti: [],
      };

      if (this.askTestingMode) {
        data.testing_mode = this.renewAfterFreePeriod ? 'rinnova' : 'disdici';
      }

      if (this.askHardening) {
        data.hardening = this.hardening.length > 0 ? this.hardening : ['Nessuno'];
      }

      if (this.askLocation && this.sceltaLocationDeploy) {
        data.provision_location = this.sceltaLocationDeploy;
      }

      if (this.askLocationBackup && this.sceltaLocationBackup) {
        data.provision_backup_location = this.sceltaLocationBackup;
      }

      if (!this.fatturaMe) {
        data.id_reseller = Number(this.login.id_azienda);
      }

      if (this.note) {
        data.note = this.note;
      }

      for (const prodotto of Object.values(this.groupedProducts)
        .map(c => c.items as Product[])
        .flat()) {
        data.prodotti.push({
          codice: prodotto.codice,
          qt: prodotto.qt ?? 1,
          dominio: prodotto.dominio,
          os: prodotto.os,
          webserver: prodotto.webserver,
          provision_db: prodotto.provision_db,
          provision_php: prodotto.provision_php,
        });
      }

      const offer = new OffersResource();
      return offer.save(data);
    },

    async submit(action: OpzioniAction) {
      this.action = action;

      if (this.sending || this.productsCount === 0) {
        return;
      }
      this.sending = true;

      try {
        const response = await this.createOffer(action);
        if (!response) {
          this.sending = false;
          return;
        }

        this.offertaNr = response.id_offerta;

        if (this.fatturaMe) {
          const company = useCompanyStore();
          if (company.id) {
            company.refresh();
          } else {
            this.login.refresh();
          }
        }

        if (action == 'confirm') {
          this.$router.push({
            name: 'confirm_offer',
            params: { id: response.id_offerta },
          });
        } else {
          this.nextStep();
        }
      } finally {
        this.sending = false;
      }
    },
  },
});
</script>
