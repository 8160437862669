<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Configurazione')">
    <div class="question-wrapper" v-if="wizard.askDb">
      <h3>{{ $t('Versione MySQL')}}</h3>
      <p>{{ $t('Indicaci quale versione di MySQL vuoi attivare.') }}</p>
      <wizard-question-options name="mysql" v-model="wizard.db" :options="wizard.opzioniDb" :null-label="_t('Non necessario')"/>
    </div>
    <div class="question-wrapper" v-if="wizard.askPhp">
      <h3>{{ $t('Versione PHP')}}</h3>
      <p>{{ $t('Indicaci quale versione di PHP vuoi attivare.') }}</p>
      <wizard-question-options name="php" v-model="wizard.php" :options="wizard.filteredOpzioniPhp" :null-label="_t('Non necessario')"/>
    </div>
    <div class="question-wrapper">
      <h3>{{ $t('Tecnologie e applicativi')}}</h3>
      <p>{{ $t('Indicaci quali di queste tecnologie verranno utilizzate.') }}</p>
      <wizard-question-hardening v-model="wizard.hardening" />
    </div>
  </wizard-form>
</template>

<script lang="ts" setup>
import { $t as _t } from '@/i18n';
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import WizardQuestionHardening from '../questions/wizard-question-hardening.vue';
import wizardQuestionOptions from '../questions/wizard-question-options.vue';
import WizardForm from '../wizard-form.vue';


defineOptions({
  name: 'WizardStepHardening',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => wizard.askHardening || wizard.askDb || wizard.askPhp,
});

defineExpose({
  isEnabled,
});
</script>
