<template>
  <div v-if="wizard.askSsl" class="riepilogo-wrapper">
    <ul>
      <wizard-summary-option
        :name="_t('Certificato SSL')"
        :value="wizard.codiceSsl ? wizard.codiceSsl : _t('Non attivare')"
        editStep="WizardStepSsl"
        ouia-id="wizard-summary-ssl"
      />
    </ul>
  </div>
</template>

<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import { useWizard } from '../composition';
import { $t as _t } from '@/i18n';

const wizard = useWizard();
</script>
