<template>
  <wizard-summary-option
    v-if="wizard.askLocation && wizard.sceltaLocationDeploy"
    :name="_t('Location principale')"
    :value="wizard.deployLocations[wizard.sceltaLocationDeploy]"
    editStep="WizardStepLocation"
    ouia-id="wizard-summary-location-deploy"
  />
  <wizard-summary-option
    v-if="wizard.askLocation && wizard.askLocationBackup && wizard.sceltaLocationBackup"
    :name="_t('Backup Location')"
    :value="wizard.backupLocations[wizard.sceltaLocationBackup]"
    editStep="WizardStepLocation"
    ouia-id="wizard-summary-location-backup"
  />
</template>


<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import { useWizard } from '../composition';
import { $t as _t } from '@/i18n';

const wizard = useWizard();
</script>
